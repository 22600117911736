// :data-content="hText"

<template>
  <span tabindex="1" @blur="hidePopover()" style="cursor: pointer">
    <span
      ref="popover"
      class="app_to_hide d-inline-block text-primary"
      title="Comentarios"
      @click="showComments()"
    >
      <!-- @mouseover="showPopover()" -->
      <i class="fa fa-comment fa-2x"></i>
    </span>

    <app-modal-basic ref="dMod">
      <!-- <div class="form-group">
        <label for>Estado</label>
        <br>
        <SelectStatus v-model="status"></SelectStatus>
      </div> -->
      <div>
        <i class="fa fa-comment"></i>&nbsp;
        <span>{{ comment }}</span>
      </div>
      <hr />
      <div v-if="comments">
        <div class="mb-1 text-dark" v-for="(l, i) in comments" :key="i">
          <b>{{ l.user_name }} : </b> <span> {{ l.comment }} </span>
        </div>
      </div>
      <div class="form-group">
        <label for>Comentario</label>
        <app-textarea v-model="new_comment"></app-textarea>
      </div>
      <app-button-submit @click="save()"></app-button-submit>
    </app-modal-basic>
  </span>
</template>

<script>
import $ from "jquery";
import { DentalClinicService } from "../service";
// import SelectStatus from "../../service-module/quotes/SelectStatus";
import moment from "moment";
// import _ from "lodash";

moment.locale("es");

export default {
  components: {
    // SelectStatus
  },
  props: {
    comment: {},
    quote_id: {}
    // curr_status: {}
  },

  beforeDestroy() {
    this.hidePopover();
  },
  data: () => ({
    showingPopover: false,
    count: 0,
    comments: undefined,
    status: undefined,
    new_comment: undefined
  }),
  mounted() {
    $(this.$refs.popover).popover({
      trigger: "manual",
      html: true,
      delay: 1
    });
  },
  methods: {
    letsComment() {
      if (!this.comments) this.getComments();
      this.$refs.dMod.show();
      this.new_comment = "";
      $(this.$refs.popover).popover("hide");
      $(".app_to_hide").popover("hide");
      this.status = this.curr_status;
    },
    save() {
      DentalClinicService.saveQuoteComment({
        quote_id: this.quote_id,
        // status_id: this.status,
        comment: this.new_comment
      }).then(() => {
        this.$refs.dMod.hide();
        this.comments = undefined;
        if (this.status != this.curr_status) {
          this.$emit("statusChanged", this.status);
        }
      });
    },
    getComments() {
      return new Promise(rsv => {
        if (this.comments != undefined) {
          rsv(this.comments);
        } else {
          DentalClinicService.getQuoteComments({
            quote_id: this.quote_id
          }).then(res => {
            this.comments = res;
            rsv(res);
          });
        }
      });
    },
    showPopover() {
      $(this.$refs.popover).popover("show");
      this.showingPopover = true;
    },
    showComments() {
      if (this.showingPopover) {
        this.hidePopover();
        // this.letsComment();
      } else {
        this.showPopover();
        this.getComments().then(res => {
          let hText = this.comment + "<br>";
          for (let it of res) {
            hText += "<b>" + it.user_name + ":</b> " + it.comment + "<br>";
            hText +=
              "<small class='text-muted'>" +
              moment(it.created_at).fromNow() +
              "</small><br>";
          }
          $(this.$refs.popover).attr("data-content", hText);
          this.showPopover();
        });
      }
    },
    hidePopover() {
      $(this.$refs.popover).popover("hide");
      this.showingPopover = false;
    }
  }
};
</script>

<style></style>
