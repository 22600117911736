<template>
  <div>
    <div class="alert alert-danger" v-show="errorMessage">
      <i class="fas fa-bell"></i> {{ errorMessage }}
    </div>
    <div class="form-group">
      <DurationSelect v-model.number="duration" :key="interval" :interval="interval">
      </DurationSelect>
    </div>
    <app-button-submit @click="save()" :loading="loading"></app-button-submit>
  </div>
</template>
<script>
// import selectDuration from "./select-duration";
import {DentalClinicService} from "../service";
import {myMoment, momentDateTimeFormat} from "../../utils/myMoment";
import DurationSelect from "./DurationSelect.vue";

export default {
  components: {
    // selectDuration,
    DurationSelect,
  },

  // directives
  // filters

  props: {
    // quoteId: {}
  },

  data: () => ({
    quote: {},
    duration: 0,
    loading: false,
    errorMessage: null,
    interval: 5,
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    async load(quote) {
      this.reset();
      this.quote = quote;
      this.duration = quote.duration;
      let res = await DentalClinicService.getEmployeeConfig(quote.employee_id);
      this.interval = res.interval_service || 5; // valor por defecto es min
    },
    reset() {
      this.duration = 0;
      this.loading = false;
    },
    save() {
      this.loading = true;
      this.errorMessage = null;
      let res = DentalClinicService.saveQuote({
        change_duration: 1,
        id: this.quote.id,
        ini_date: this.quote.ini_date,
        interval: this.interval,
        end_date: myMoment(this.quote.ini_date)
          .add(this.duration, "minute")
          .format(momentDateTimeFormat)
      });
      res.then(() => {
        this.$emit("saved");
      }, error => {
        if(error.response && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "Algo salio mal";
        }
      });
      res.finally(() => {
        this.loading = false;
      })
    }
  }
};
</script>

<style></style>
