<template>
  <div>
    <quoteForm
      ref="elForm"
      @saved="$emit('saved')"
    >
    </quoteForm>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
// import { ServiceFactory } from "../../service-module/service";
import { myMoment, momentDateTimeFormat } from "../../utils/myMoment";
import quoteForm from "./Form.vue";

export default {
  components: {
    quoteForm,
  },

  // directives
  // filters

  props: {
    interval: {}
  },

  data: () => ({
    date: new Date(),
    quote_id: 0,
    employee_id: 0,
    duration: 0,
    times: [],
    new_time: "",
    intInterval: 10
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    load(quote) {
      this.quote_id = quote.id;
      this.employee_id = quote.employee_id;
      this.duration = quote.duration;
      // this.$refs.elForm.setPreventDuration(quote.duration);
      // this.$refs.elForm.setEmployeeId(quote.employee_id);
      // this.$refs.elForm.setGroup(1);
      this.$refs.elForm.setValue(quote);
    },
    save() {
      DentalClinicService.saveQuote({
        reprogram_quote: 1,
        id: this.quote_id,
        ini_date: this.new_time,
        group: this.$refs.elForm.getGroup(),
        employee_id: this.employee_id,
        interval: this.interval || this.intInterval,
        end_date: myMoment(this.new_time)
          .add(this.duration, "minute")
          .format(momentDateTimeFormat)
      }).then(() => {
        this.$emit("submitted");
      });
    }
    // getTimes() {
    //   ServiceFactory.scheduleTimes({
    //     employee_id: this.employee_id,
    //     interval: this.duration,
    //     ini_date: this.date,
    //     end_date: this.date,
    //     only_free: 1
    //   }).then(list => {
    //     this.times = list;
    //   });
    // }
  }
};
</script>

<style></style>
